import React from 'react'
import Image from '../assets/img/about_1.png';
import {teamsMember} from '../data';
import {teams} from '../data';
import bgService from '../assets/img/bg-services.png'
import bg from '../assets/img/team/IMG_0446.JPG'

const About = () => {
  return (
  <div>
  <section id='about' className='section bg-white'>
    <div className='container mx-auto'>
        <div className='flex flex-col xl:flex-row
        gap-24'>
            <img className='hidden md:block object-cover h-full
            w-[200px] lg:w-[300px] md:mx-auto 
            lg:mx-0 rounded-2xl'
            src={Image} alt=''/>
            
            <div className='flex flex-col
            items-center text-center
            lg:items-start 
            lg:text-left'>
                <div className='flex flex-col'>
                    <h2 className='text-3xl 
                    lg:text-4xl
                    font-medium 
                    text-purple-900
                    lg:font-extrabold mb-3'>
                        Tentang Psikolog
                    </h2>
                    
                    <p className='mb-8 text-black'>
                        Saya adalah psikolog klinis yang berfokus pada permasalahan klinis dewasa. Saya menempuh pendidikan Magister Profesi Psikologi Klinis Dewasa pada Perguruan Tinggi di Unika Soegijapranata Semarang. Panggilan saya adalah mendengar keluh kesah mereka yang terpinggirkan, mereka yang hilang akan harapannya. Pengalaman saya dalam menangani permasalahan klien berkisar sejak tahun 2021. Masalah demi masalah yang mereka hadapi bukanlah menjadi masalah atau beban bagi saya. Tetapi sebuah cerita kehidupan yang penuh makna.

                        <br/>
                        Klinik Psikolog Vira saya dirikan sejak tahun 2021 dengan motto "Be Happy To Better Me". Klinik ini harapannya menjadi wadah atau tempat bagi mereka yang tidak memiliki tempat untuk bercerita dan tempat bagi mereka yang putus harapan. Selama kehidupan berjalan masalah tidak akan hilang tetapi kita hanya butuh tempat dan sosok yang tepat untuk kita terus bertumbuh dan berprogres.
                    </p>
                    <p className='mb-2
                     text-purple-600 italic'>
                        With Love
                    </p>
                    <h2 className='text-3x1 
                    lg:text-4xl
                    font-primary 
                    text-purple-900
                    lg:font-bold mb-2 italic'>
                        Vira Pusparani,M.Psi, Psikolog
                    </h2>
                </div>
            </div>
        </div>
    </div>
  </section>
  <section id='teams' className='section 
  bg-tertiary bg-cover h-56 lg:h-full' style={{ backgroundImage:`url(${bg})`,backgroundRepeat:"no-repeat"}}>
    <div className='container mx-auto'>
        <div className='flex flex-col items-center
        text-center'>
            <h2 className='section-title lg:text-white font-bold text-sm lg:text-2xl -mt-6'>
               TIM KAMI
            </h2>
            <p className='subtitle text-white italic text-sm lg:text-2xl -mt-4 mb-4'>
                Melayani dengan Hati dalam Sinergi
            </p>
        </div>
            <div className='grid'>
                <div className="flex justify-center">
                    <div className="rounded-lg shadow-lg bg-white max-w-lg mb-[35rem]">
                    </div>
                </div>
            </div>
            {/* {teams.map((item,index)=>{
                const {icon} = item;
                return (<div className='mb-5'>
                            <div className="flex justify-center"  key={index}>
                                <div className="rounded-lg shadow-lg bg-white max-w-sm">
                                    <a href="#!" className=''>
                                        <img className="rounded-t-lg transform transition-transform duration-300 hover:scale-110" src={icon} alt="" width={300}/>
                                    </a>
                                </div>
                            </div>
                        </div>)
            })} */}
        {/* <div className='grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-8'>
            {teamsMember.map((item,index)=>{
                const {icon} = item;
                return <div className="flex justify-center"  key={index}>
                <div className="rounded-lg shadow-lg bg-white max-w-sm">
                  <a href="#!" data-mdb-ripple="true" data-mdb-ripple-color="light">
                    <img className="rounded-t-lg  transform transition-transform duration-300 hover:scale-110" src={icon} alt=""/>
                  </a>
                </div>
              </div>
            })}
        </div> */}
    </div>
  </section>
  </div>
  )
}

export default About