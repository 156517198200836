import React from 'react'
import TestimonialSlider from './TestimonialSlider'

const Testimonials = () => {
  return <section id='testimonials' className='section bg-slate-300'>
   <div className='container mx-auto'>
      <div className='flex flex-col items-center text-center'>
            <h2 className='section-title text-purple-900'>
             Kata Mereka
            </h2>
            <p className='subtitle text-black italic'>
                Klinik Psikolog Vira
            </p>
      </div>
      <TestimonialSlider/>
    </div> 
   
  </section>
   
   
}

export default Testimonials