import React from 'react'

import {navigation} from '../data';

import {Link} from 'react-scroll';

const Nav = () => {
  return (
    <nav>
        <ul className='flex space-x-8 capitalize text-[15px]'>
            {
                navigation.map((item,index)=>{
                    return (
                        <li className='text-purple-800 hover:text-purple-900 cursor-pointer'
                        key={index}>
                            <Link 
                            to={item.href} 
                            activeClass='active'
                            spy={true} 
                            smooth={true} 
                            duration={500}
                            offset={-70}
                            className='transition-all'>
                                {item.name}
                            </Link>
                        </li>
                    )
                })
            }
            <li className='text-purple-800 hover:text-purple-900 cursor-pointer'>
                <a href='https://virapusparani.blogspot.com/' className='transition-all' target={'blank'}>Blog</a>
            </li >
            
        </ul>
    </nav>
  )
}

export default Nav