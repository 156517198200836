import React from 'react'
import {testimonials} from '../data'

import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import '../swiper.css';

import {Autoplay,Pagination} from 'swiper';
const TestimonialSlider = () => {
  return (
    <>
     <Swiper 
     slidesPerView={2}
     spaceBetween={30}
     pagination={{
        clickable:true,
     }}
     autoplay={{
        delay:2500,
        disableOnInteraction:false
     }}
     modules={[Autoplay,Pagination]}
     className='mySwiper'
     >
        {testimonials.map((item,index)=>{
            const {authorImg} = item;
            return <SwiperSlide key={index}>
                    <div className=''>
                        <img className='rounded-2xl'
                         src={authorImg} alt=''/>
                    </div>
            </SwiperSlide>
        })}
     </Swiper>
    </>
  )
}

export default TestimonialSlider