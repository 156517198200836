import React from 'react'

import Bg from '../assets/img/bg-hero.png'
const Quote = () => {
  return (
    <section id='quote' className="lg:h-[55vh] md:h-24 flex
    items-center lg:py-0 
    overflow-hidden bg-cover bg-center" style={{ backgroundImage:`url(${Bg})`,backgroundRepeat:"no-repeat"}}>
        <div className='container mx-auto h-full' >
            <div className="flex flex-col items-center justify-center h-full pt-8">
                <p className="text-center text-lg" style={{color:"#96088f"}}>
                Menangislah dan bersedihlah semampumu. <br/>Setelah itu jangan lupakan kemampuan dirimu yang sebenarnya. 
                <br/>Kemampuanmu untuk menjadi versi yang terbaik dari sebelumnya. <br/><span className='text-purple-900 font-extrabold	 italic'>"To be Happy to Better you"</span>.
                </p>
                <h1 className="mb-2 text-2xl text-purple-800 text-center mt-2">
                    Vira Pusparani, M.Psi, Psikolog
                </h1>
            </div>
        </div>
    </section>
  )
}

export default Quote