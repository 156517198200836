import React from 'react'

import {social} from '../data';

const Footer = () => {
   
  return <footer className='bg-white
  py-12'>
    <div className='container mx-auto'>
        <div className='flex flex-col
        lg:flex-row
        space-y-6 lg:space-y-0
        items-center
        justify-between'>
            <div className='flex space-x-6 items-center
            justify-center'>
                {social.map((item,index)=>{
                    const {href,icon} = item;
                    return <a 
                    className='text-purple-900 text-base'
                    href={href}
                    target={'blank'}
                    key={index} >
                        {icon}
                    </a>
                })}
            </div>
            <p className='text-purple-900 font-bold'>&copy; 2022 - {new Date().getFullYear()}  Support By <a href='https://vrasmedia.com' target={'blank'}>Vrasmedia IT Solution</a></p>
        </div>
    </div>
    
  </footer>
}

export default Footer