
import React, {useState,useEffect} from 'react'

import Logo from '../assets/img/logo.png';
import Nav from './Nav';
import NavMobile from './NavMobile';
import Socials from './Socials';

const Header = () => {
  const [bg,setBg] = useState(false);
  useEffect(()=>{
    window.addEventListener('scroll',()=>{
        return window.scrollY > 50 ? setBg(true) : 
        setBg(false);
    });
  })
  return (
    <header className={`${bg ? 'bg-zinc-50 h-20':'h-24'} 
    flex items-center fixed top-0 w-full 
    text-white z-10 transition-all`}>
        <div className='container mx-auto h-full flex items-center 
            justify-between'>
            <a href='#'>
                <img src={Logo} className="w-40" alt='' />
            </a>
            <div className='hidden lg:block'>
                <Nav/>
            </div>
            <div className='hidden lg:block'>
                <Socials/>
            </div>
            <div className='lg:hidden'>
                <NavMobile/>
            </div>
        </div>
    </header>
  )
}

export default Header