import React from 'react';
import About from './components/About';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer';
import BackToTop from './components/BackToTop';
import Quote from './components/Quote';
import Wa from './components/Wa';
import Promotion from './components/Promotion';
// import components
const App = () => {
  return (
  <div>
     <Promotion/>
    <Header/>
    <Hero/>
    <About/>
    <Services/>
    <Testimonials/>
    <Quote/>
    <Contact/>
    <Footer/>
    <BackToTop/>
    <Wa/>
  </div>
  );
};

export default App;
