import React from 'react'

import {services} from '../data';
import bgService from '../assets/img/bg-services.png'

const Services = () => {
  return <section id='services' className='section 
  bg-tertiary bg-cover' style={{ backgroundImage:`url(${bgService})`,backgroundRepeat:"no-repeat"}}>
    <div className='container mx-auto'>
        <div className='flex flex-col items-center
        text-center'>
            <h2 className='section-title lg:text-white font-bold'>
                Layanan Untukmu
            </h2>
            <p className='subtitle text-black italic'>
                Klinik Psikolog Vira
            </p>
        </div>
        <div className='grid grid-cols-2 lg:grid-cols-4 md:grid-cols-2 gap-8'>
            {services.map((item,index)=>{
                const {icon,name} = item;
                return <div className="flex justify-center"  key={index}>
                <div className="rounded-lg shadow-lg bg-white max-w-sm">
                  <a href="#!" data-mdb-ripple="true" data-mdb-ripple-color="light">
                    <img className="rounded-t-lg  transform transition-transform duration-300 hover:scale-110" src={icon} alt=""/>
                  </a>
                    <h5 className="text-purple-900 font-semibold text-center lg:text-xl text-md mb-2 p-6">{name}</h5>
                </div>
              </div>
            })}
        </div>
    </div>
  </section>
}

export default Services