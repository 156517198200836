import React from 'react'

import {contact} from '../data';
import bgContact from '../assets/img/bg-contact.png'
const Contact = () => {
  return <section id='contact' className='
  section bg-primary bg-cover' style={{ backgroundImage:`url(${bgContact})`,backgroundRepeat:"no-repeat"}}>
    <div className='container mx-auto'>
        <div className='flex flex-col items-center
        text-center'>
            <h2 className='section-title text-purple-900 text-bold'>
                Hubungi Saya
            </h2>
            <p className='subtitle text-black italic'>
                Klinik Psikolog Vira
            </p>
        </div>
        <div className='flex flex-col
        lg:flex-row'>
            <div className='flex flex-1 flex-col justify-between space-y-2 mb-12 lg:mb-0 lg:pt-2'>
                {contact.map((item,index)=>{
                    const {icon,title,subtitle,description} = item;

                    return (
                        <div className='flex' key={index}>
                            <div className='text-purple-900
                            rounded-sm w-14 h-14
                            mt-2 mb-4 lg:mb-0
                            text-2xl'>
                                {icon}
                            </div>
                            <div className='w-[250px]'>
                                <h4 className='font-body text-md
                                mb-1 text-purple-900 uppercase font-extrabold'>{title}</h4>
                                <p className='mb-1 text-black'>{subtitle}</p>
                                <p className='text-black
                                font-normal'>{description}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className='space-y-8 w-full max-w-[780px]'>
                    <iframe
                    title='iframe' 
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15838.293892713007!2d110.4665306!3d-7.0592977!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e708f00fa2455c3%3A0xa3d63e4d02d2f034!2sKlinik%20Psikolog%20Vira!5e0!3m2!1sid!2sid!4v1719373893621!5m2!1sid!2sid" 
                    className='w-full lg:w-[728px] lg:h-[450px] rounded-lg shadow-lg bg-white '
                    style={{ border: 0 }} 
                    allowFullScreen=""
                    loading="lazy" 
                    referrerPolicy='no-referrer-when-downgrade'/>
            </div>
        </div>
    </div>
  </section>
}

export default Contact