import React from 'react'

import Bg from '../assets/img/bg.png'
const Hero = () => {
  return (
    <section id='home' className="lg:h-[85vh] flex
    items-center py-32 lg:py-0 
    overflow-hidden bg-cover bg-center" style={{ backgroundImage:`url(${Bg})`,backgroundRepeat:"no-repeat"}}>
        <div className='container mx-auto h-full' >
            <div className="flex flex-col items-center justify-center h-full pt-8">
                <h1 className="mb-2 text-4xl text-purple-800 font-bold text-center">
                    Vira Pusparani, M.Psi, Psikolog
                </h1>
                <p className="text-lg text-center italic text-purple-600">
                    Setiap jiwa berhak untuk bertumbuh kuat dan menjadi terang untuk menerangi.
                </p>
                <div className="mt-4">
                    <a href='https://wa.me/6281336129619' target={'blank'} className="px-6 py-2 text-center text-white bg-purple-600 hover:bg-purple-900 rounded-md shadow-md" >
                        Konseling Sekarang
                    </a>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Hero