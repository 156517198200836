
import React, {useState} from 'react';
import './promotion.css';
const Promotion = () => {
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    return (
        <>
           {show && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
                    <div className="custom-modal flex max-w-4xl relative">
                        <button onClick={handleClose} className="z-50  md:-top-5 md:-right-5 top-8 font-extrabold right-20 px-2 py-2 fixed bg-white text-black rounded">X</button>
                        <div onClick={() => window.open('https://play.google.com/store/apps/details?id=com.hiyou&hl=id&gl=US&pli=1', '_blank')} className='custom-modal-2'/>
                    </div>
                </div>
            )}
        </>
    );
}

export default Promotion